import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import home1 from '@img/home1.webp';
import home1_1 from '@img/home1_1.webp';
import '@styles/CarrouselHome.scss';

const CarrouselHome = ({categories}) => {
    const navigate = useNavigate();
    const [category, setCategory] = useState({});

    const items = [{home1, home1_1}];

    const handleChange = async(selectedOption) => {
        setCategory(selectedOption)
        navigate('/search', { state:{filterId: selectedOption} });
    };

    const goToSearch = () =>{
        navigate('/search', { state:{filterId: category} });
    }

    const formatOptionLabel = ({ label, image }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {image && <img src={image} alt="imagen" style={{ width: '20px', marginRight: '10px', height:'auto' }} />}
            <div>{label}</div>
        </div>
    );

    return (
        <div className="carrousel-home">
            <OwlCarousel className='owl-theme' items={1} loop autoplay={false} dots={false} mouseDrag={false} touchDrag={false} >
                {items.map((item, index) => (
                    <div className='item' key={index}>
                        <picture>
                            <source media="(max-width: 900px)" srcSet={item.home1_1} />
                            <source media="(min-width: 901px)" srcSet={item.home1} />
                            <img src={item.home1} alt="firulife-img"  key={index}/>
                        </picture>
                    </div>
                ))}
            </OwlCarousel>
            <div className="content-carrousel">
                <p className="title-carrousel">Todo lo que tu mascota necesita en un solo lugar.</p>
                <div className="search-carrousel">
                    <div className="select-search">
                        <Select
                            className="select-input"
                            classNamePrefix="select-input"
                            options={categories}
                            formatOptionLabel={formatOptionLabel}
                            placeholder="Seleccionar el servicio..."
                            onChange={handleChange}
                        />
                    </div>
                    <div className="Search-SearchIcon" onClick={() => goToSearch()}>
                        Buscar
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarrouselHome;
